import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { ElementSize } from '~/models/enums/element-size';
import { CarouselType } from '~/models/enums/carousel-type';
import Widget from '~/models/widget';

@Component
export default class CardCarouselMixin extends Vue {
  @Prop({ type: Object, default: () => null }) data!: Widget | null;
  @Prop({ type: Array, default: () => [] }) items!: any[];
  @Prop({ type: String, default: CarouselType.PRODUCT }) type!: CarouselType;
  @Prop({ type: Number, default: 4 }) perPage!: number;
  @Prop({ type: Number, required: false }) perGroup!: number;
  @Prop({ type: Boolean, default: false }) shadow!: boolean;
  @Prop({ type: Number, required: false }) itemsLimit!: number;
  @Prop({ type: Boolean, default: false }) showMoreCard!: boolean;
  @Prop({ type: Boolean, default: false }) showCustomBackground!: boolean;

  private elementSizeEnum = ElementSize;
  private carouselTypeEnum = CarouselType;
}
