
import { Component, mixins, Prop, Watch } from 'nuxt-property-decorator';
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import cuid from 'cuid';
import DeviceMixin from '~/mixins/device.mixin';
import { CarouselType } from '~/models/enums/carousel-type';
import CardCarouselMixin from '~/mixins/card-carousel.mixin';

@Component({
  name: 'dn-card-carousel',
})
export default class CardCarousel extends mixins(
  DeviceMixin,
  CardCarouselMixin,
) {
  @Prop({ type: String, default: '' }) sliderClass!: string;

  private swiper: Swiper | null = null;
  private cuid: string = '';

  private get swiperName(): string {
    return `swiper-${this.cuid}`;
  }

  private get isBannerCarousel(): boolean {
    return this.type === CarouselType.BANNER;
  }

  private get isProductCarousel(): boolean {
    return this.type === CarouselType.PRODUCT;
  }

  private get carouselHeight(): { [key: string]: string } | null {
    let height: number;
    if (this.type !== CarouselType.PRODUCT) {
      height = !this.isMobileOrTablet ? 284 : 240;
      return { minHeight: height + 'px' };
    } else {
      return null;
    }
  }

  private get limitedItems() {
    if (this.itemsLimit === undefined) {
      return this.items;
    }

    return this.items.slice(0, Math.min(this.items.length, this.itemsLimit));
  }

  private get lastItem() {
    const itemsCount = this.items.length;

    if (this.itemsLimit === undefined) {
      return this.items[itemsCount - 1];
    }

    return this.items[
      itemsCount < this.itemsLimit ? itemsCount : this.itemsLimit
    ];
  }

  private get showIndicators() {
    const displaysDopeBackgrounds = !!this.data?.results?.backgroundImage;
    return (
      this.limitedItems.length > this.perPage &&
      !this.isMobileOrTablet &&
      displaysDopeBackgrounds
    );
  }

  created() {
    this.cuid = cuid();
  }

  mounted() {
    this.$nextTick(() => {
      this.initCarousel();
    });
  }

  @Watch('$route')
  onRouteChange() {
    // fix for sorting issue
    this.initCarousel();
  }

  initCarousel() {
    this.swiper = new Swiper(`#${this.swiperName}`, {
      modules: [Navigation, Pagination],
      slidesPerView: this.perPage,
      spaceBetween: 32,
      breakpoints: {
        0: {
          slidesPerView: 2.08,
          slidesPerGroup: this.perGroup === undefined ? 1 : 2,
          spaceBetween: 12,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 32,
        },
        1024: {
          slidesPerView: this.perPage,
          slidesPerGroup: this.perGroup === undefined ? 1 : this.perGroup,
          spaceBetween: 32,
        },
      },
      navigation: {
        nextEl: `.swiper-button-next.${this.swiperName}`,
        prevEl: `.swiper-button-prev.${this.swiperName}`,
      },
      ...(this.showIndicators
        ? {
            pagination: {
              el: `.swiper-pagination.${this.swiperName}`,
              clickable: true,
              renderBullet: (index: number, className: string) => {
                const dotCount: number =
                  index * this.perPage < this.limitedItems.length - this.perPage
                    ? this.perPage
                    : this.limitedItems.length - this.perPage * index;

                return `<span class="swiper-pagination-bullet swiper-pagination-bullet-group">
                ${Array.from({ length: dotCount })
                  .map(
                    (_, i) =>
                      `<span class="swiper-pagination-bullet-group-dot"></span>`,
                  )
                  .join('')}
              </span>`;
              },
            },
          }
        : {}),
    });
  }

  @Watch('isMobileOrTablet')
  @Watch('$route')
  setCarousel(current: boolean) {
    this.$nextTick(() => {
      if (!current) {
        this.initCarousel();
      }
    });
  }
}
